import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class Global {
    baseURL = '';
    production = false;
    userId = '';
    name = '';
    phone = '';
    authToken = '';
    type = '';
    email = '';
    password = '';
    company = '';
    userType = '';
    user_address: any;
    user_longitude : any;
    user_latitude:any;
    userAddress: any;
    mainAddress: any;
    updateETAFlag = false;
    data: any;
    location_list: any;
    SenderID = "993270460240";
    OneSignalAPPID = "4e6f888a-fa86-4c75-9503-2b035b8c7e8e";
    OneSignalServerKey = "ODU1YjhjZDYtYzk5MC00MjU2LTk5YTItOTZhOTkyZGUyYTY3";
    NotificationToken = '';
    GetNotificationsCheck = true;
    vehicleIcons = [];
    isApp = true;
    deviceType : any;
    vehicleId : any;
    URL : any;
    nativeVideo : any;
    playVideo : any;
    isAdmin: any = false;
    constructor(public toastController: ToastController, public alertCtrl: AlertController) {

        if (this.production) {
            this.baseURL = 'https://dashboard.etatrackplus.com/eta-development/index.php/';
        } else {
            this.baseURL = 'https://dashboard.etatrackplus.com/eta-development/index.php/';

    
        }

    }

    setData(value) {
        this.data = value;
    }

    getData() {
        return this.data;
    }

    async presentToast(message, duration = 2000) {
        const toast = await this.toastController.create({
            message,
            duration
        });
        toast.present();
    }
    async alertMsg(header, msg) {
        const alert = await this.alertCtrl.create({
          header: header,
          message: msg,
          buttons: ['OK']
        });
      
        await alert.present();
      }
      async presentAlert(header, msg, button){
        const alert = await this.alertCtrl.create({
          header: header,
          message: msg,
          buttons: [button]
        });
        await alert.present();
  
      }
}
